/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}

textarea:focus,
input:focus {
  outline: none;
}

@import "media";
@import "config";
@import "plugins/owl.carousel.min.scss";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

//OWL animation
.animated {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;

  -webkit-animation-delay: 500ms;
  animation-delay: 500ms;
}

.animated-custom-duration-05 {
  -webkit-animation-duration: 0.5s !important;
  animation-duration: 0.5s !important;
}

.animate-out {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms;
}

body {
  font-family: "Montserrat";
  width: 100vw;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  @include max1370 {
    width: 1200px;
  }
  @include max1200 {
    width: 100%;
  }
  @include max576 {
    padding: 0 4%;
  }

  &.fluid {
    width: 100%;
    max-width: unset;
  }
}

.header {
  .container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    @include max576 {
      padding: 0;
    }
  }

  &__contacts {
    display: flex;
    align-items: center;
    padding-left: 40px;
    @include max1200 {
      display: none;
    }
  }

  &__phone {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $main-color;
    text-decoration: none;
    margin-right: 13px;
  }

  &__work-time {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $grey-font;
  }

  &__logo {
    position: relative;
    left: -8%;
    padding-top: 10px;
    padding-bottom: 20px;
    @include max1200 {
      left: 2%;
    }
  }

  .hamburger-menu {
    display: none;
    @include max800 {
      display: block;
      margin-top: 17px;
      margin-right: 20px;
    }
  }

  &__lang {
    padding-right: 40px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $grey-font;
    width: 90px;
    justify-content: space-between;
    @include max800 {
      display: none;
    }

    a {
      color: $grey-font;
      text-decoration: none;

      &.active {
        color: $black;
      }
    }
  }

  &__fixed {
    width: 100%;
    height: 80px;
    background: #ffffff;
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
    position: fixed;
    top: -100px;
    z-index: 999;
    transition: all 0.3s linear;

    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */

    /* black */

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include max576 {
        padding: 0;
      }
    }

    &.active {
      top: 0px;
      transition: all 0.3s linear;
    }

    .nav {
      color: #000000;
      margin-top:22px;
      justify-content: space-around;
      @include max800 {
        display: none;
      }

      .header__logo {
        display: inline-block;
        width: 142px;
        padding: 0;
        left: -2%;
        top: -2px;


        img {
          width: 100%;
        }
      }
    }

    .header__logo {
      display: none;
      @include max800 {
        display: block;
      }
    }

    .hamburger-menu {
      display: none;
      margin-right: 20px;
      @include max800 {
        display: block;
        position: relative;
        top: -3px;
      }
    }
  }


  // Вторая менюшка черная
  &__mobile2 {
    display: none;
    @include max800 {
      display: flex;
      height: 48px;
      background: black;
      justify-content: space-between;
    }

    .header__contacts {
      display: flex;
      padding-left: 20px;
    }

    .header__work-time {
      color: #dadada;

      span, strong{


        @include max576 {
          display: block;
          text-align: right;
        }
      }
    }

    .header__lang {
      .active {
        color: #fff;
      }
    }

    .header__lang {
      @include max1200 {
        margin-top: 0px;
        /* padding-right: 0px; */
        width: 58px;
        padding: 0;
        display: flex;
        margin-right: 25px;

      }
    }
  }

  // mobile 2
}

// header

// mobileHamburger
$bar-width: 20px;
$bar-height: 1px;
$bar-spacing: 7px;

.hamburger-menu {
  width: 44px;
  height: 44px;
  cursor: pointer;
  background: #d9d9d9;

  //box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);
  //border-radius: 6px;
  //padding: 10px;
  padding: 12px;
  box-sizing: border-box;
  transition: all 0.3s linear;

  &.animate {
    background: $main-color;
    transition: all 0.3s linear;
  }

  .bar {
    top: 3px;
  }

  .bar,
  .bar:after,
  .bar:before {
    width: $bar-width;
    height: $bar-height;
    background: #000;
  }

  .bar {
    position: relative;
    transform: translateY($bar-spacing);
    //background: rgba(255, 255, 255, 1);
    transition: all 0ms 300ms;
    width: 20px;

    &.animate {
      background: rgba(255, 255, 255, 0);
    }
  }

  .bar:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: $bar-spacing;
    //background: rgba(255, 255, 255, 1);
    transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .bar:after {
    width: 20px;
    content: "";
    position: absolute;
    left: 0;
    top: $bar-spacing;
    //background: rgba(255, 255, 255, 1);
    transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .bar.animate:after {
    width: 20px;
    top: 0;
    transform: rotate(45deg);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    background: #fff;
  }

  .bar.animate:before {
    bottom: 0;
    transform: rotate(-45deg);
    transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    background: #fff;
  }
}

.sidebar {
  width: 340.24px;
  height: 586.65px;
  /* white */
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
  position: fixed;
  right: -100%;
  //top: 130px;
  top: 171px;
  z-index: 99;
  transition: all 0.5s ease-in-out;


  &.active {
    transition: all 0.5s ease-in-out;
    right: 0;
  }



  .nav {
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #000000;
    padding-left: 20px;

    li {
      border-bottom: 1px solid #e5e5e5;
      //height: 63px;
      line-height: 61px;
      padding-left: 10px;

      &:last-child {
        border: none;
      }
    }
  }
}

//  вся ширина
.video-section {
  position: relative;

  video {
    height: 844px;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
    @include max1370 {
      height: 615px;
    }
    @include max576 {
      height: 85vw;
    }
  }

  &__darker {
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
    position: absolute;
    top: 0;
    width: 100%;
    height: 142px;
  }

  &__text {
    width: 990px;
    background: #fff;
    padding-right: 100px;
    padding-left: 100px;
    padding-top: 42px;
    text-align: center;
    z-index: 2;
    position: relative;
    margin: 0 auto;
    margin-top: -149px;
    box-sizing: border-box;

    @include max1200 {
      width: 95%;
      background: #fff;
      padding-right: 30px;
      padding-left: 30px;
    }
    @include max576 {
      width: 100%;
      padding-right: 6px;
      padding-left: 6px;
    }
  }

  &__text1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;

    &:after {
      content: "";
      height: 1px;
      display: block;
      margin: 0 auto;
      background: #000;
      width: 85px;
      margin-top: 53px;
      margin-bottom: 38px;
      @include max576 {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
  }

  &__text2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    /* or 229% */

    text-align: center;
    color: $grey-font;
    @include max576 {
      line-height: 26px;
    }
  }

  .bee-icon {
    margin-top: 40px;
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 20px;
  z-index: 2;
  @include max1470 {
    width: 94%;
  }
  // @include max1370 {
  //   width: 90%;
  // }
  @include max800 {
    display: none;
  }

  li {
    cursor: pointer;
    transition: all 0.3s linear;
    border-bottom: 2px solid transparent;

    &:hover {
      border-bottom: 2px solid $main-color;
      transition: all 0.3s linear;
    }
  }
}

$quality_anim_spped: 700ms;

.section-quality {

  margin-top: 60px;
  &__heading {
    display: block;
    text-align: center;
    font-size: 24px;
    line-height: 150%;
  }

  &__wrapper {
    margin: 0 auto;
    margin-top: 60px;
    width: 96%;
    display: flex;
    // flex-wrap: wrap;
    gap: 10px;
    height: 600px;
    @include max1670 {
      gap: 0;
    }
    @include max1470 {
      height: 530px;
    }
    @include max1200 {
      gap: 8px;
      height: auto;
      width: 100%;
      align-items: center;
      flex-direction: column;
    }
    @include  max800 {

    }
  }

  &__item {
    width: 290px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 39px;
    padding-bottom: 60px;
    cursor: pointer;
    transition: all $quality_anim_spped ease;
    //transition: all 10s ease;
    background-size: cover !important;
    background-repeat: no-repeat !important;


    @include max1670 {
      width: 16.6%;
    }
    @include max1470 {
      padding: 25px;
    }
    @include max1200 {
      width: 720px;
      flex-wrap: wrap;
      background-position: center !important;
      height: 80px;
      flex: unset;
      align-items: unset;
      flex-direction: unset;
      padding: 27px 24px !important;
      display: block;
      -webkit-box-orient: unset;
    }

    @include max800 {
      //height: 80px;
      width: 96%;

    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(360deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.1) 60%);
    }



    &:last-child {
      .section-quality__title {
        @include max480 {
          position: relative;
          top: -16px;
        }
      }

    }


    &:hover {
      flex-grow: 3;
      transition: all $quality_anim_spped ease;
      @include max1200 {
        padding-bottom: 90px;
        height: 400px;
      }
      @include max576 {
        height: 139vw;
      }

      .section-quality__title {
        height: fit-content;
        top: 39px;
        left: 39px;
        align-items: flex-start;
        flex-direction: column;
        transform: rotate(0);
        transition: all $quality_anim_spped ease;
        //transition: all 10s ease;
        @include max1470 {
          top: 25px;
          left: 25px
        }
        @include max1200 {
          margin: 0;
          top: unset;
          left: unset;
        }

        &::after {
          width: 100px;
          @include max1200 {
            width: 224px;
          }
        }
      }

      .section-quality__text {
        opacity: 1;
        visibility: visible;
        margin: 0;
        margin-top: 23%;
        transition-delay: 500ms !important;
        transition: all $quality_anim_spped linear;

        @include max1470 {
          margin-top: 7vw;
        }
        @include max1280 {
          margin-top: 8vw;
        }
        @include max1200 {
          width: 100%;
          height: auto;
          //margin-top: 4vw;
          transition-delay: 0ms !important;
          transition: all 0ms linear;
        }
      }

      &::before {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
      }
    }

  }

  &__title {
    position: absolute;
    // bottom: 60px;
    top: 42%;
    display: flex;
    align-items: center;
    max-height: 290px;
    width: 100%;
    height: 100%;
    font-size: 24px;
    line-height: 150%;
    text-transform: uppercase;
    color: #ffffff;
    transform: rotate(-90deg);
    transition: all $quality_anim_spped ease;
    //transition: all 10s ease;


    @include max1670 {
      font-size: 20px;
    }
    @include max1200 {
      transform: rotate(0deg);
      position: relative;
      height: auto;
      width: auto;
      display: block;
      //margin: 0 auto;
      top: unset;
      left: unset;
      // justify-content: center;
    }





    &::after {
      content: "";
      width: 0;
      height: 6px;
      position: absolute;
      bottom: -35px;
      // top: 60px;
      background-color: $main-color;
      transition: width 0.3s;
      //transition: all 10s ease;
      @include max1200 {
        bottom: -25px;
        left: 0;
        transition: width $quality_anim_spped;
      }
    }


  }

  &__text {
    opacity: 0;
    visibility: hidden;
    position: relative;
    width: 100%;
    margin: auto;
    font-size: 16px;
    line-height: 200%;
    color: $grey;
    text-align: left;

    transition: all 0ms ease;
    //transition: all 10s ease;
    transition-delay: 0ms;


    @include max1470 {
      font-size: 15px;
      line-height: 170%;
    }
    @include max1200 {
      width: 0;
      height: 0;
      margin: 0;
      margin-top: -59px !important;
      display: block;
      position: relative;
      margin: 0 !important;
      margin-top: 55px !important;

    }

    a {
      color: $main-color;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }


    ul {
      list-style: disc;
      column-count: 2;
      padding-left: 30px;
      column-gap: 50px;
      margin-top: 23px;
      @include max1470 {
        padding-left: 19px;
        column-gap: 70px;
      }
      @include max576 {
        column-count: unset;
      }
    }

  }
}


.section-stages {
  margin-top: 100px;
  @include max576 {
    margin-top: 80px;
  }

  .container {
    display: flex;
    @include max1200 {
      flex-wrap: wrap;
    }
  }

  &__left {
    width: 522px;
    background: $grey;
    overflow: hidden;
    @include max1280 {
      width: 40%;
    }
    @include max1200 {
      width: 38.5%;
      margin-left: 2%;
    }
    @include max800 {
      width: 47.5%;
    }
    @include max576 {
      width: 100%;
      margin: 0;
    }

    &-img {
      height: 400px;
      overflow: hidden;
      @include max1370 {
        height: auto;
      }
      @include max1280 {
        //height: auto;
        height: 306px;
      }
      @include max800 {
        height: 420px;
      }
      @include max576 {
        height: 77vw;
      }
    }

    &-text {
      color: $black;
      font-weight: 700;
      font-size: 40px;
      line-height: 54px;
      padding-right: 70px;
      padding-top: 80px;
      padding-left: 120px;
      padding-bottom: 57px;
      position: relative;
      z-index: 1;
      @include max1280 {
        padding-left: 93px;
        padding-bottom: 73px;
      }
      @include max1200 {
        font-size: 33px;
        padding-right: 35px;
        padding-left: 69px;
      }
      @include max576 {
        font-size: 24px;
        padding-right: 40px;
        padding-top: 40px;
        padding-left: 80px;
        line-height: 34px;
        padding-bottom: 40px;
      }

      &:before {
        content: "";
        width: 40px;
        height: 40px;
        background: $main-color;
        position: absolute;
        left: 60px;
        top: 54px;
        @include max1280 {
          left: 36px;
        }
        @include max1200 {
          left: 19px;
          top: 32px;
        }
        @include max576 {
          left: 33px;
          top: 32px;
          width: 30px;
          height: 30px;
        }
      }
    }

    img {
      width: 101%;
      transition: all 0.3s linear;
      transform: scale(1);

      @include max1200 {
        width: 111%;
      }
      @include max800() {
        height: 100%;
        width: auto;
      }

      &:hover {
        transition: all 0.3s linear;
        transform: scale(1.1);
      }
    }
  }

  &__middle {
    padding-top: 53px;
    width: 509px;
    @include max1280 {
      width: 32%;
      padding-top: 20px;
    }
    @include max1200 {
      width: 50%;
    }
    @include max576 {
      width: 100%;
      padding-top: 40px;
    }

    li {
      display: flex;
      width: 100%;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: $grey-font;
      list-style: none;
      padding-left: 76px;
      padding-right: 76px;
      margin-bottom: 71px;
      align-items: center;

      @include max1200 {
        padding-left: 24px;
      }
      @include max576 {
        padding-left: 0;
        margin-bottom: 57px;
        padding-right: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__num {
    font-weight: 100;
    font-size: 64px;
    line-height: 36px;
    color: black;
    position: relative;
    margin-right: 30px;

    &:before {
      content: "";
      background: #dadada;
      opacity: 0.5;
      border-radius: 50%;
      width: 53px;
      display: block;
      height: 53px;
      position: absolute;
      top: -10px;
      left: 13px;
      z-index: -1;
    }
  }

  &__right {
    overflow: hidden;
    @include max1280 {
      width: 28%;
    }
    @include max1200 {
      display: flex;
      width: 100%;
      height: 378px;
      margin-top: 20px;
      height: auto;
    }
    @include max576 {
      margin-top: 40px;
      flex-wrap: wrap;
    }

    img {
      transition: all 0.3s linear;
      transform: scale(1);
      @include max1200 {
        width: 50vw;
        height: 241px;
        object-fit: cover;
      }
      @include max576 {
        //width: 88vw;
        width: 92vw;
        height: 88vw;
      }

      &:hover {
        transition: all 0.3s linear;
        transform: scale(1.1);
      }
    }

    &-bottom {
      z-index: 1;
      position: relative;
      margin-top: -5px;
      padding: 60px 30px;
      padding-bottom: 0;
      @include max1280 {
        //padding: 92px 30px 20px 30px;
      }
      @include max1200 {
        margin-top: 0px;
      }
      @include max576 {
        padding: 60px 30px;
        padding-left: 20px;

      }

      img {
        position: absolute;
        z-index: -2;
        top: 0;
        left: 0;
      }

      li {
        color: #fff;
        list-style: none;
        font-weight: 600;
        display: flex;
        align-items: center;

        &:last-child {
          margin-top: 48px;
        }
      }

      .section-stages__num {
        color: #fff;

        &:before {
          //top: 14px;
        }
      }
    }
  }
}

.section-advantages {
  margin-top: 100px;
  position: relative;
  overflow: hidden;
  @include max800 {
    margin-top: 80px;
  }

  .container {
    height: 542px;
    position: relative;
    //background: url("../images/adv.jpg");
    //background-size: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    //transition: all 5s linear;
    //&:hover {
    //  background-size: 110%;
    //  transition: all 30s linear;
    //}

    @include max800 {
      height: auto;
    }
  }

  &__slider {
    font-size: 24px;
    line-height: 36px;
    color: #dadada;
    margin-left: 120px;
    width: 294px !important;
    position: relative;
    @include max1200 {
      margin-left: 8%;
      width: 44% !important;
    }
    @include max800 {
      width: 100% !important;
      font-size: 22px;
      margin-top: 40px;
      margin-left: 0;
    }

    .slide {
      @include max800 {
        margin-right: 20px;
      }
    }

    strong {
      font-weight: 600;
      display: block;
    }

    .owl-dots {
      //background: rgba(0, 0, 0, 0.4);
      padding: 10px 15px;

      display: inline-flex;
      position: absolute;
      //top: 413px;
      //left: 2px;
      padding-bottom: 4px;

      margin-top: 30px;
      position: relative;
      left: -16px;
      @include max800 {
        margin-top: 4px;
        margin-bottom: 30px;
      }
    }

    .owl-dot {
      background: rgba(255, 255, 255, 0.5) !important;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      margin: 0 4px;
      display: block;

      &.active {
        background: none !important;
        //border: 2px solid $main-color;
        border: 3px solid #ff6600;
        width: 14px;
        height: 14px;
        position: relative;
        top: -2px;
      }
    }
  }

  &__bgs {
    img {
      display: none;
      position: absolute;
      z-index: -1;
      width: 100%;
      object-fit: cover;
      top: 0;
      left: 0;
      @include max1370 {
        height: 100%;
      }

      &:first-child {
        display: block;
      }
    }
  }

  &__wrap {
    width: 990px;
    height: 335px;
    border: 1px solid $main-color;
    padding: 45px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include max1200 {
      width: 96%;
    }

    @include max800 {
      margin: 32px 0;
      width: 92%;
      flex-wrap: wrap;
      height: auto;
      padding: 14px;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 40px;
    line-height: 54px;
    padding-left: 75px;

    color: #ffffff;
    position: relative;
    width: 213px;
    width: 380px;
    @include max1200 {
      font-size: 32px;
    }
    @include max800 {
      font-size: 24px;
      line-height: 34px;
      width: 100%;
      padding-left: 66px;
    }

    &:before {
      content: "";
      width: 40px;
      height: 40px;
      background: #ff6600;
      position: absolute;
      left: 0px;
      top: 2px;
      @include max800 {
        width: 30px;
        height: 30px;
        left: 15px;
        top: -1px;
      }
    }
  }
}

.section-clients {
  margin-top: 100px;

  .container {
    display: flex;
    justify-content: space-between;
    @include max1200 {
      flex-wrap: wrap;
      text-align: center;
    }
  }

  &__text {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    width: 302px;
    @include max1200 {
      width: 100%;
      text-align: center;
      margin-bottom: 40px;
    }
  }

  &__slider {
    width: 1000px !important;
    @include max1280 {
      width: 800px !important;
    }
    @include max1200 {
      width: 100% !important;
    }

    a {
      display: block;
      width: 275px;
      height: 112px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #dadada;
      width: 100%;
      margin: 0 !important;

      img {
        max-height: 70px;
        max-height: 80px;
        width: auto !important;
      }
    }

    .owl-nav {
      position: absolute;
      left: -400px;
      top: 80px;
      @include max1370 {
        //left: -260px;
        top: 114px;
        left: -20%;
      }
      @include max1280 {
        left: -400px;
      }
    }

    .owl-prev,
    .owl-next {
      height: 40px;
    }

    .owl-prev {
      margin-right: 20px;
    }

    .owl-dots {
      //background: rgba(0, 0, 0, 0.4);
      padding: 10px 15px;

      display: inline-flex;
      padding-bottom: 4px;
      margin: 0 auto;
      margin-top: 40px;
    }

    .owl-dot {
      background: rgba(0, 0, 0, 0.3) !important;

      width: 11px;
      height: 11px;
      border-radius: 50%;
      margin: 0 4px;
      display: block;

      &.active {
        background: none !important;
        //border: 2px solid $main-color;
        border: 3px solid #ff6600;
        width: 14px;
        height: 14px;
        position: relative;
        top: -2px;
      }
    }
  }
}



.section-projects {
  margin-top: 100px;
  width: 100vw;
  overflow-x: hidden;

  @include max576 {
    overflow: hidden;
  }

  .container {
    display: flex;
    @include max1200 {
      flex-wrap: wrap;
    }
  }

  &__text {
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    /* or 135% */

    color: $black;
    width: 300px;
    position: relative;
    padding-left: 30px;
    padding-top: 30px;

    @include max1200 {
      font-size: 32px;
      padding: 0;
      padding-top: 30px;
      width: 100%;
    }

    &:before {
      content: "";
      width: 24px;
      height: 24px;
      background: #ff6600;
      position: absolute;
      left: 0px;
      top: 2px;

      @include max1200 {
        left: 13px;
      }
      @include max576 {
        left: 13px;
        width: 30px;
        height: 30px;
        top: 22px;
      }
    }

    span {
      @include max1200 {
        padding-left: 40px;
      }
      @include max800 {
        display: block;
      }
      @include max576 {
        display: block;
        font-size: 24px;
        line-height: 34px;
        padding-left: 63px;
      }
    }
  }

  &__descrs {
    margin-top: 50px;
    //margin-left: 113px;
    margin-left: 130px;
    @include max1200 {
      margin-left: 0px;
      margin-top: 16px;
    }
    @include max576 {
      margin-top: 26px;
      width: 109%;
      left: 14px;
      position: relative;
    }
  }

  &__descr {
    width: 430px;
    background: $grey;
    padding: 48px 55px;
    font-size: 18px;
    display: none;

    @include max1200 {
      margin: 0 auto;
      width: 96%;
      padding: 30px 30px;
    }
    @include max576 {
      font-size: 18px;
      padding-left: 44px;
    }

    &:first-child {
      display: block;
    }

    &-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      width: 79%;
      @include max576 {
        width: 100%;
        font-size: 20px;
      }
    }

    &-address {
      font-weight: 300;
      margin-top: 40px;
      margin-bottom: 7px;
      @include max1200 {
        margin-top: 15px;
      }

      &:before {
        content: "";
        width: 23px;
        height: 24px;
        background: url(../images/Location.svg);
        background-size: contain;
        display: inline-block;
        position: relative;
        top: 6px;
        background-repeat: no-repeat;
        margin-right: 5px;
      }
    }

    p {
      line-height: 30px;

      //margin-bottom: 10px;
      margin-bottom: 16px;
      width: 73%;
      color: $grey-font;
      font-weight: 400;
      @include max576 {
        width: 100%;
        font-size: 18px;
      }

      span,strong {
        color: $black;
        padding-left: 0px;
        font-weight: 600;
        @include max576 {
          font-size: 18px;
          display: inline-block;
        }
      }
    }
  }

  &__slider {
    width: 200%;
    left: 13%;
    position: relative;
    @include max1200 {
      left: 0;
    }
    @include max576 {
      width: 110% !important;
      left: -5%;
    }

    .projects__see-all {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      text-transform: uppercase;

      color: #FFFFFF;
      width: 250px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content:center;
      position: absolute;
      bottom: 0;
      background: rgba(0,0,0,0.7);
      cursor: pointer;
      transition: all 0.3s linear;
      &:hover {
        background: $main-color;
      }
    }

    .owl-item {
      //width: 931px;
    }

    .owl-item {
      opacity: 0.3;
      transition: all 0.3s linear;
    }

    .owl-item.active {
      opacity: 1;
    }

    .owl-item.active + .owl-item.active {
      opacity: 0.3;
    }

    .owl-nav {
      margin-top: 48px;
      position: relative;
      left: 740px;
      @include max1370 {
        left: 485px;
      }
      @include max1200 {
        left: 75%;
        position: absolute;
        //top: -139px;
        top: -155px;
      }

      @include max740 {
        left: 65%;
      }
      @include max576 {
        display: flex;
        left: 0%;
        position: absolute;
        top: -220px;
        width: 100%;
        justify-content: space-between;
        width: 87%;
        left: 6%;
      }
    }

    .owl-prev {
      margin-right: 100px;
    }

    .slide {
      width: 931px;
      height: 444px;
      display: block;
      @include max576 {
        width: 100vw;
        height: 65vw;
      }


      .section-projects__proj-slider {
        height: 100%;
        .owl-item {
          opacity: 1;
          transition: all 0.3s linear;
        }
        .owl-item.active + .owl-item.active {
          opacity: 1;
        }

        img {
          width: 931px;
          height: 444px;
          object-fit: cover;
          @include max576 {
            width: 106%;
            height: 65vw;
          }
        }
      }

    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .owl-dots {
      background: rgba(0, 0, 0, 0.4);
      padding: 10px 15px;

      display: inline-flex;
      position: absolute;
      top: 415px;
      left: 2px;
      padding-bottom: 4px;
      @include max576 {
        top: unset;
        margin: 0 auto;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .owl-prev {
      transform: rotate(180deg);
    }
    .owl-dot {
      background: rgba(255, 255, 255, 0.5) !important;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      margin: 0 4px;
      display: block;

      &.active {
        background: none !important;
        //border: 2px solid $main-color;
        border: 3px solid #ff6600;
        width: 14px;
        height: 14px;
        position: relative;
        top: -2px;
      }
    }
  }
}

.section-equipment {
  margin-top: 100px;
  background: $grey;
  padding-top: 44px;
  position: relative;
  padding-bottom: 190px;
  margin-bottom: 494px;

  @include max800 {
    text-align: center;
  }

  @include max1370 {
    margin-bottom: 380px;
  }
  @include max576 {
    margin-top: 80px;
    padding-top: 14px;
    text-align: center;
    margin-bottom: 130vw;
  }

  &__title {
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    /* or 135% */
    text-align: left;

    color: $black;
    //width: 300px;
    position: relative;
    padding-left: 30px;
    padding-top: 30px;

    @include max1200 {
      font-size: 32px;
      width: 60%;
      line-height: 40px;
      padding-left: 40px;
    }
    @include max576 {
      display: block;
      font-size: 24px;
      line-height: 34px;
      padding-left: 63px;
      width: 100%;
    }

    &:before {
      content: "";
      width: 24px;
      height: 24px;
      background: #ff6600;
      position: absolute;
      left: 0px;
      top: 2px;
      @include max1200 {
        left: 12px;
      }
      @include max576 {
        left: 12px;
        width: 30px;
        height: 30px;
        top: 20px;
      }
    }
  }

  &__slider {
    margin-top: 40px;
    position: absolute !important;
    top: 141px;
    width: 100%;
    /* margin: 0 auto; */
    //left: 17%;
    width: 96%;
    /* margin: 0 auto; */
    left: 2%;
    @include max1200 {
      left: 0%;
    }
    @include max800 {
      width: 98% !important;
      left: 1%;
    }

    .owl-item {
      @include max800 {
      }
    }

    .slide {
      width: 450px;
      height: 600px;
      position: relative;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      display: block;
      /* or 150% */

      background-size: 100% !important;
      background-repeat: no-repeat;
      padding: 28px;

      transition: all ease-in-out 0.3s !important;
      text-align: left;

      @include max1370 {
        width: 380px;
        height: 493px;
      }
      @include max800 {
        width: 48vw;
        height: 63vw;
      }
      @include max576 {

        width: 99vw;
        height: 132vw;
      }

      &:hover {
        background-size: 110% !important;
        transition: all ease-in-out 0.3s !important;
      }

      /* white */

      color: #ffffff;

      img {
        position: absolute;
        top: 0;
        z-index: 0;
      }

      &__subtitle {
        font-size: 16px;
        z-index: 2;
      }

      &__title {
        z-index: 2;
      }
    }

    .owl-dots {
      background:none !important;
      padding: 10px 15px;

      display: inline-flex;
      padding-bottom: 4px;
      margin: 0 auto;
      margin-top: 40px;
    }

    .owl-dot {
      background: rgba(0, 0, 0, 0.3) !important;
      //background:#999 !important;

      width: 11px;
      height: 11px;
      border-radius: 50%;
      margin: 0 4px;
      display: block;

      &.active {
        background: none !important;
        //border: 2px solid $main-color;
        border: 3px solid #ff6600;
        width: 14px;
        height: 14px;
        position: relative;
        top: -2px;
      }
    }

    .owl-nav {
      position: absolute;
      top: -110px;
      //right: 27%;
      right: 10%;
      @include max740 {
        right: 2%;
      }
      @include max576 {
        display: none;
      }
    }

    .owl-prev {
      transform: rotate(180deg);
      margin-right: 100px;
    }
  }
}

.section-certificates {
  background: $grey;
  padding-bottom: 70px;
  padding-top: 70px;

  &__text {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    width: 273px;
    margin-right: 94px;
    @include max1280 {
      margin-right: 40px;
    }
    @include max1200 {
      width: 100%;
      text-align: center;
      margin: 0;
      margin-bottom: 40px;
    }
  }

  .container {
    display: flex;
    @include max1200 {
      flex-wrap: wrap;
      text-align: center;
    }
  }

  &__slider {
    width: 73% !important;
    @include max1200 {
      width: 100% !important;
    }

    .slide {
      width: 350px;
      @include max1280 {
        width: 300px;

      }
      @include max1200 {
        text-align: center;
      }
      @include max1050 {
        width: 50vw;
      }
      @include max576 {
        width: 92vw;
      }

      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        width: 80%;
        @include max1280 {
          width: 87%;
        }
        @include max1200 {
          width: 100%;
        }
      }

      img {
        width: 140px !important;
        height: auto;
        margin-bottom: 24px;
        @include max1200 {
          margin: 0 auto;
          margin-bottom: 24px;
          width: 73% !important;
        }
        @include max1050 {
          width: 50% !important;
        }
      }

      p {
        @include max1050 {
          padding: 0 19%;
        }
      }
    }

    .owl-dots {
      background: none !important;
      padding: 10px 15px;

      display: inline-flex;
      padding-bottom: 4px;
      margin: 0 auto;
      margin-top: 40px;
    }

    .owl-dot {
      background: rgba(0, 0, 0, 0.3) !important;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      margin: 0 4px;
      display: block;

      &.active {
        background: none !important;
        //border: 2px solid $main-color;
        border: 3px solid #ff6600;
        width: 14px;
        height: 14px;
        position: relative;
        top: -2px;
      }
    }

    .owl-nav {
      position: absolute;
      left: -35%;
      top: 46%;

      @include max1370 {
        //left: -338px;
        left: -37%;
        top: 54%;
      }

      @include max1280 {
        left: -312px;
      }
    }

    .owl-prev {
      margin-right: 20px;
    }

    .owl-prev,
    .owl-nav {
      height: 40px;
    }
  }
}

.section-suppliers {
  padding-top: 70px;
  padding-bottom: 70px;

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }

  &__wrap {
    display: flex;
    justify-content: space-around;
    width: 91%;
    margin: 0 auto;
    margin-top: 64px;
    @include max1670 {
      justify-content: space-between;
    }
    @include max1200 {
      flex-wrap: wrap;
    }
  }

  &__suplier {
    width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include max1670 {
      width: auto;
    }
    @include max1280 {
      width: 13%;
    }
    @include max1200 {
      width: 26%;
      margin-bottom: 20px;
    }
    @include max800 {
      width: 33%;
      margin-bottom: 20px;
    }
    @include max576 {
      width: 50%;
      margin-bottom: 20px;
    }

    img {
      max-height: 122px;
      @include max1670 {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
      @include max1050 {
        max-height: unset;
        max-width: 84%;
      }
      @include max576 {
        max-width: 64%;
      }
    }
  }
}

.section-contacts {
  border-top: 1px solid #dadada;

  &__title {
    font-weight: 700;
    margin-bottom: 5px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    @include max1370 {
      justify-content: space-around;
    }
    @include max1200 {
      flex-wrap: wrap;
    }
  }

  &__left {
    padding-top: 35px;
    width: 458px;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    line-height: 30px;
    @include max1370 {
      width: 32%;
    }
    @include max1200 {
      width: 100%;
      padding-left: 5%;
    }

    span {
      color: $grey-font;
    }

    p {
      margin-bottom: 5px;
    }
  }

  &__right {
    padding-top: 35px;
    width: 458px;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    line-height: 30px;
    @include max1370 {
      width: 32%;
    }
    @include max1200 {
      width: 100%;
      padding-left: 5%;
    }

    span {
      color: $grey-font;
    }

    p {
      margin-bottom: 5px;
    }
  }

  &__middle {
    width: 380.02px;
    background: $grey;
    padding: 0 55px;
    height: 444px;
    //height: 461px;
    height: 431px;
    @include max1370 {
      width: 310.02px;
      padding: 0px 28px;
    }
    @include max1200 {
      width: 100%;
      width: 90%;
      margin: 0 auto;
      margin-top: 45px;
    }

    &-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      margin: 0 auto;
      margin-top: 32px;
      text-align: center;
      margin-bottom: 30px;
    }

    input {
      height: 33px;
      border: none;
      color: $grey-font;
      background: none;
      width: 100%;
      border-bottom: 1px solid #dadada;
      margin-bottom: 30px;
      font-size: 14px;

      &:focus,
      &:active {
        border: none;
        color: $black;
        border-bottom: 1px solid $black;
      }
    }

    textarea {
      border: none;
      font-size: 14px;
      color: $grey-font;
      background: none;
      width: 100%;
      border-bottom: 1px solid #dadada;
      margin-bottom: 30px;
      height: 55px;
      font-family: "Montserrat";

      &:focus,
      &:active {
        border: none;
        color: $black;
        border-bottom: 1px solid $black;
      }
    }

    .accept {
      font-size: 12px;
      line-height: 18px;
      /* or 150% */

      /* gray/1 */

      color: $grey-font;
    }

    .btn {
      margin-top: 14px;
      width: 100%;
      @include max1370 {
      }

      span {
        border-color: $grey transparent transparent transparent;
      }
    }
  }

  &__map {
    width: 450px;
    height: 225.15px;

    margin-top: 36px;
    @include max1370 {
      width: 96%;
    }
    @include max1200 {
      height: 48vw;
      width: 94%;
    }
    @include max576 {
      height: 94vw;
    }
  }
}

.btn {
  width: 270px;
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;

  /* primary / 2 */

  background: $main-color;
  transition: all 0.3s linear;

  &:hover {
    transition: all 0.3s linear;
    background: lighten($main-color, 10%);
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    border-style: solid;
    border-width: 20px 20px 0 0;
    border-color: #ffffff transparent transparent transparent;
  }
}

.footer {
  margin-top: 30px;
  background: $black;
  height: 50px;
  @include max576 {
    height: auto;
    padding: 40px 0px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    @include max1200 {
      padding: 0 2%;
    }
    @include max576 {
      flex-wrap: wrap;

    }
  }

  &__socials {
    display: flex;
    align-items: center;
    @include max576 {
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
    }

    svg {
      width: 28px;
      height: auto;
      margin-right: 12px;
    }
  }

  &__copyright {
    color: $grey-font;
    font-size: 12px;
    @include max576 {
      width: 60%;
      margin: 0 20%;
      text-align: center;
      margin-top: 30px;
      margin-bottom: 30px;
      line-height: 22px;
    }
  }

  &__dev {
    display: flex;
    align-items: center;
    color: $grey-font;
    font-size: 12px;

    @include max576 {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    svg {
      width: 95px;
      height: auto;
      margin-left: 14px;
    }
  }
}

.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;


  /* Hide the browser's default checkbox */
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border: 1px solid #dadada;
    &.validate-err {
    border: 1px solid red;
    }
  }

  /* On mouse-over, add a grey background color */
  input[type="checkbox"] input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  input[type="checkbox"]:checked ~ .checkmark {
    background-color: $main-color;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input[type="checkbox"]:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 7px;
    top: -1px;
    width: 7px;
    height: 15px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}


input.validate-err , textarea.validate-err {
  border-bottom: 1px solid red;
}


/**
 * Modals ($modals)
 */


/* 1. Ensure this sits above everything when visible */
.modal {
  position: fixed;
  z-index: 100000000; /* 1 */
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-heading {
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
  text-align: center;
  margin-bottom: 36px;
}

.modal.is-visible {
  visibility: visible;
}

.modal-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.modal.is-visible .modal-overlay {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.modal-wrapper {
  position: absolute;
  z-index: 9999;

  padding: 46px;

  width: 393px;
  // height: 517px;

  box-sizing: border-box;

  background: #ffffff;
  box-shadow: 0px 0px 41px rgba(0, 0, 0, 0.25);
}

.modal-transition {
  transition: all 0.3s 0.12s;
  transform: translateY(-10%);
  opacity: 0;
}

.modal.is-visible .modal-transition {
  transform: translateY(0);
  opacity: 1;
}

input[type="text"] {
  margin-bottom: 16px;
}

input[type="number"] {
  margin-bottom: 16px;
}

//
.close {
  background: url(../images/form-close-X.svg) no-repeat;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 20px;
  right: 20px;
  &:hover {
    cursor: pointer;
  }
}

#thanks {
  p {
    text-align: center;
  }
}


.arr1 {
  transition: all 0.3s linear;
  &:hover {

    transition: all 0.3s linear;
    fill: $main-color
  }
}
.arr2 {
   transition: all 0.3s linear;
   &:hover {

     transition: all 0.3s linear;
     fill: $main-color
   }
 }

.up-btn {
  position: fixed;
  right: 1%;
  bottom: 10%;
  z-index: 999;
  transform: scale(0.9);
  transition: all 0.3s linear;
  cursor: pointer;

  &:hover {
    transform: scale(1);
    transition: all 0.3s linear;
  }
}