@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

textarea {
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none; /*remove the resize handle on the bottom right*/
}

textarea:focus,
input:focus {
  outline: none;
}

/*
 * Основные точки перехода
 * Идем от Десктопа к мобильникам
 */
/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item, .owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none;
}

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.owl-height {
  transition: height 0.5s ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 0.4s ease;
}

.owl-carousel .owl-item .owl-lazy:not([src]), .owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 0.1s ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.4s ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.animated {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-delay: 500ms;
  animation-delay: 500ms;
}

.animated-custom-duration-05 {
  -webkit-animation-duration: 0.5s !important;
  animation-duration: 0.5s !important;
}

.animate-out {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms;
}

body {
  font-family: "Montserrat";
  width: 100vw;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
}
@media (max-width: 1370px) {
  .container {
    width: 1200px;
  }
}
@media (max-width: 1200px) {
  .container {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .container {
    padding: 0 4%;
  }
}
.container.fluid {
  width: 100%;
  max-width: unset;
}

.header .container {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
@media (max-width: 576px) {
  .header .container {
    padding: 0;
  }
}
.header__contacts {
  display: flex;
  align-items: center;
  padding-left: 40px;
}
@media (max-width: 1200px) {
  .header__contacts {
    display: none;
  }
}
.header__phone {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #FF6600;
  text-decoration: none;
  margin-right: 13px;
}
.header__work-time {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #777777;
}
.header__logo {
  position: relative;
  left: -8%;
  padding-top: 10px;
  padding-bottom: 20px;
}
@media (max-width: 1200px) {
  .header__logo {
    left: 2%;
  }
}
.header .hamburger-menu {
  display: none;
}
@media (max-width: 800px) {
  .header .hamburger-menu {
    display: block;
    margin-top: 17px;
    margin-right: 20px;
  }
}
.header__lang {
  padding-right: 40px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #777777;
  width: 90px;
  justify-content: space-between;
}
@media (max-width: 800px) {
  .header__lang {
    display: none;
  }
}
.header__lang a {
  color: #777777;
  text-decoration: none;
}
.header__lang a.active {
  color: #000;
}
.header__fixed {
  width: 100%;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: -100px;
  z-index: 999;
  transition: all 0.3s linear;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  /* black */
}
.header__fixed .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 576px) {
  .header__fixed .container {
    padding: 0;
  }
}
.header__fixed.active {
  top: 0px;
  transition: all 0.3s linear;
}
.header__fixed .nav {
  color: #000000;
  margin-top: 22px;
  justify-content: space-around;
}
@media (max-width: 800px) {
  .header__fixed .nav {
    display: none;
  }
}
.header__fixed .nav .header__logo {
  display: inline-block;
  width: 142px;
  padding: 0;
  left: -2%;
  top: -2px;
}
.header__fixed .nav .header__logo img {
  width: 100%;
}
.header__fixed .header__logo {
  display: none;
}
@media (max-width: 800px) {
  .header__fixed .header__logo {
    display: block;
  }
}
.header__fixed .hamburger-menu {
  display: none;
  margin-right: 20px;
}
@media (max-width: 800px) {
  .header__fixed .hamburger-menu {
    display: block;
    position: relative;
    top: -3px;
  }
}
.header__mobile2 {
  display: none;
}
@media (max-width: 800px) {
  .header__mobile2 {
    display: flex;
    height: 48px;
    background: black;
    justify-content: space-between;
  }
}
.header__mobile2 .header__contacts {
  display: flex;
  padding-left: 20px;
}
.header__mobile2 .header__work-time {
  color: #dadada;
}
@media (max-width: 576px) {
  .header__mobile2 .header__work-time span, .header__mobile2 .header__work-time strong {
    display: block;
    text-align: right;
  }
}
.header__mobile2 .header__lang .active {
  color: #fff;
}
@media (max-width: 1200px) {
  .header__mobile2 .header__lang {
    margin-top: 0px;
    /* padding-right: 0px; */
    width: 58px;
    padding: 0;
    display: flex;
    margin-right: 25px;
  }
}

.hamburger-menu {
  width: 44px;
  height: 44px;
  cursor: pointer;
  background: #d9d9d9;
  padding: 12px;
  box-sizing: border-box;
  transition: all 0.3s linear;
}
.hamburger-menu.animate {
  background: #FF6600;
  transition: all 0.3s linear;
}
.hamburger-menu .bar {
  top: 3px;
}
.hamburger-menu .bar,
.hamburger-menu .bar:after,
.hamburger-menu .bar:before {
  width: 20px;
  height: 1px;
  background: #000;
}
.hamburger-menu .bar {
  position: relative;
  transform: translateY(7px);
  transition: all 0ms 300ms;
  width: 20px;
}
.hamburger-menu .bar.animate {
  background: rgba(255, 255, 255, 0);
}
.hamburger-menu .bar:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 7px;
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.hamburger-menu .bar:after {
  width: 20px;
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.hamburger-menu .bar.animate:after {
  width: 20px;
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  background: #fff;
}
.hamburger-menu .bar.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  background: #fff;
}

.sidebar {
  width: 340.24px;
  height: 586.65px;
  /* white */
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
  position: fixed;
  right: -100%;
  top: 171px;
  z-index: 99;
  transition: all 0.5s ease-in-out;
}
.sidebar.active {
  transition: all 0.5s ease-in-out;
  right: 0;
}
.sidebar .nav {
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #000000;
  padding-left: 20px;
}
.sidebar .nav li {
  border-bottom: 1px solid #e5e5e5;
  line-height: 61px;
  padding-left: 10px;
}
.sidebar .nav li:last-child {
  border: none;
}

.video-section {
  position: relative;
}
.video-section video {
  height: 844px;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}
@media (max-width: 1370px) {
  .video-section video {
    height: 615px;
  }
}
@media (max-width: 576px) {
  .video-section video {
    height: 85vw;
  }
}
.video-section__darker {
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  position: absolute;
  top: 0;
  width: 100%;
  height: 142px;
}
.video-section__text {
  width: 990px;
  background: #fff;
  padding-right: 100px;
  padding-left: 100px;
  padding-top: 42px;
  text-align: center;
  z-index: 2;
  position: relative;
  margin: 0 auto;
  margin-top: -149px;
  box-sizing: border-box;
}
@media (max-width: 1200px) {
  .video-section__text {
    width: 95%;
    background: #fff;
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (max-width: 576px) {
  .video-section__text {
    width: 100%;
    padding-right: 6px;
    padding-left: 6px;
  }
}
.video-section__text1 {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
}
.video-section__text1:after {
  content: "";
  height: 1px;
  display: block;
  margin: 0 auto;
  background: #000;
  width: 85px;
  margin-top: 53px;
  margin-bottom: 38px;
}
@media (max-width: 576px) {
  .video-section__text1:after {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.video-section__text2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  /* or 229% */
  text-align: center;
  color: #777777;
}
@media (max-width: 576px) {
  .video-section__text2 {
    line-height: 26px;
  }
}
.video-section .bee-icon {
  margin-top: 40px;
}

.nav {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 20px;
  z-index: 2;
}
@media (max-width: 1470px) {
  .nav {
    width: 94%;
  }
}
@media (max-width: 800px) {
  .nav {
    display: none;
  }
}
.nav li {
  cursor: pointer;
  transition: all 0.3s linear;
  border-bottom: 2px solid transparent;
}
.nav li:hover {
  border-bottom: 2px solid #FF6600;
  transition: all 0.3s linear;
}

.section-quality {
  margin-top: 60px;
}
.section-quality__heading {
  display: block;
  text-align: center;
  font-size: 24px;
  line-height: 150%;
}
.section-quality__wrapper {
  margin: 0 auto;
  margin-top: 60px;
  width: 96%;
  display: flex;
  gap: 10px;
  height: 600px;
}
@media (max-width: 1670px) {
  .section-quality__wrapper {
    gap: 0;
  }
}
@media (max-width: 1470px) {
  .section-quality__wrapper {
    height: 530px;
  }
}
@media (max-width: 1200px) {
  .section-quality__wrapper {
    gap: 8px;
    height: auto;
    width: 100%;
    align-items: center;
    flex-direction: column;
  }
}
.section-quality__item {
  width: 290px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 39px;
  padding-bottom: 60px;
  cursor: pointer;
  transition: all 700ms ease;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
@media (max-width: 1670px) {
  .section-quality__item {
    width: 16.6%;
  }
}
@media (max-width: 1470px) {
  .section-quality__item {
    padding: 25px;
  }
}
@media (max-width: 1200px) {
  .section-quality__item {
    width: 720px;
    flex-wrap: wrap;
    background-position: center !important;
    height: 80px;
    flex: unset;
    align-items: unset;
    flex-direction: unset;
    padding: 27px 24px !important;
    display: block;
    -webkit-box-orient: unset;
  }
}
@media (max-width: 800px) {
  .section-quality__item {
    width: 96%;
  }
}
.section-quality__item::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.1) 60%);
}
@media (max-width: 480px) {
  .section-quality__item:last-child .section-quality__title {
    position: relative;
    top: -16px;
  }
}
.section-quality__item:hover {
  flex-grow: 3;
  transition: all 700ms ease;
}
@media (max-width: 1200px) {
  .section-quality__item:hover {
    padding-bottom: 90px;
    height: 400px;
  }
}
@media (max-width: 576px) {
  .section-quality__item:hover {
    height: 139vw;
  }
}
.section-quality__item:hover .section-quality__title {
  height: fit-content;
  top: 39px;
  left: 39px;
  align-items: flex-start;
  flex-direction: column;
  transform: rotate(0);
  transition: all 700ms ease;
}
@media (max-width: 1470px) {
  .section-quality__item:hover .section-quality__title {
    top: 25px;
    left: 25px;
  }
}
@media (max-width: 1200px) {
  .section-quality__item:hover .section-quality__title {
    margin: 0;
    top: unset;
    left: unset;
  }
}
.section-quality__item:hover .section-quality__title::after {
  width: 100px;
}
@media (max-width: 1200px) {
  .section-quality__item:hover .section-quality__title::after {
    width: 224px;
  }
}
.section-quality__item:hover .section-quality__text {
  opacity: 1;
  visibility: visible;
  margin: 0;
  margin-top: 23%;
  transition-delay: 500ms !important;
  transition: all 700ms linear;
}
@media (max-width: 1470px) {
  .section-quality__item:hover .section-quality__text {
    margin-top: 7vw;
  }
}
@media (max-width: 1280px) {
  .section-quality__item:hover .section-quality__text {
    margin-top: 8vw;
  }
}
@media (max-width: 1200px) {
  .section-quality__item:hover .section-quality__text {
    width: 100%;
    height: auto;
    transition-delay: 0ms !important;
    transition: all 0ms linear;
  }
}
.section-quality__item:hover::before {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
}
.section-quality__title {
  position: absolute;
  top: 42%;
  display: flex;
  align-items: center;
  max-height: 290px;
  width: 100%;
  height: 100%;
  font-size: 24px;
  line-height: 150%;
  text-transform: uppercase;
  color: #ffffff;
  transform: rotate(-90deg);
  transition: all 700ms ease;
}
@media (max-width: 1670px) {
  .section-quality__title {
    font-size: 20px;
  }
}
@media (max-width: 1200px) {
  .section-quality__title {
    transform: rotate(0deg);
    position: relative;
    height: auto;
    width: auto;
    display: block;
    top: unset;
    left: unset;
  }
}
.section-quality__title::after {
  content: "";
  width: 0;
  height: 6px;
  position: absolute;
  bottom: -35px;
  background-color: #FF6600;
  transition: width 0.3s;
}
@media (max-width: 1200px) {
  .section-quality__title::after {
    bottom: -25px;
    left: 0;
    transition: width 700ms;
  }
}
.section-quality__text {
  opacity: 0;
  visibility: hidden;
  position: relative;
  width: 100%;
  margin: auto;
  font-size: 16px;
  line-height: 200%;
  color: #F4F1EF;
  text-align: left;
  transition: all 0ms ease;
  transition-delay: 0ms;
}
@media (max-width: 1470px) {
  .section-quality__text {
    font-size: 15px;
    line-height: 170%;
  }
}
@media (max-width: 1200px) {
  .section-quality__text {
    width: 0;
    height: 0;
    margin: 0;
    margin-top: -59px !important;
    display: block;
    position: relative;
    margin: 0 !important;
    margin-top: 55px !important;
  }
}
.section-quality__text a {
  color: #FF6600;
  text-decoration: none;
}
.section-quality__text a:hover {
  text-decoration: underline;
}
.section-quality__text ul {
  list-style: disc;
  column-count: 2;
  padding-left: 30px;
  column-gap: 50px;
  margin-top: 23px;
}
@media (max-width: 1470px) {
  .section-quality__text ul {
    padding-left: 19px;
    column-gap: 70px;
  }
}
@media (max-width: 576px) {
  .section-quality__text ul {
    column-count: unset;
  }
}

.section-stages {
  margin-top: 100px;
}
@media (max-width: 576px) {
  .section-stages {
    margin-top: 80px;
  }
}
.section-stages .container {
  display: flex;
}
@media (max-width: 1200px) {
  .section-stages .container {
    flex-wrap: wrap;
  }
}
.section-stages__left {
  width: 522px;
  background: #F4F1EF;
  overflow: hidden;
}
@media (max-width: 1280px) {
  .section-stages__left {
    width: 40%;
  }
}
@media (max-width: 1200px) {
  .section-stages__left {
    width: 38.5%;
    margin-left: 2%;
  }
}
@media (max-width: 800px) {
  .section-stages__left {
    width: 47.5%;
  }
}
@media (max-width: 576px) {
  .section-stages__left {
    width: 100%;
    margin: 0;
  }
}
.section-stages__left-img {
  height: 400px;
  overflow: hidden;
}
@media (max-width: 1370px) {
  .section-stages__left-img {
    height: auto;
  }
}
@media (max-width: 1280px) {
  .section-stages__left-img {
    height: 306px;
  }
}
@media (max-width: 800px) {
  .section-stages__left-img {
    height: 420px;
  }
}
@media (max-width: 576px) {
  .section-stages__left-img {
    height: 77vw;
  }
}
.section-stages__left-text {
  color: #000;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  padding-right: 70px;
  padding-top: 80px;
  padding-left: 120px;
  padding-bottom: 57px;
  position: relative;
  z-index: 1;
}
@media (max-width: 1280px) {
  .section-stages__left-text {
    padding-left: 93px;
    padding-bottom: 73px;
  }
}
@media (max-width: 1200px) {
  .section-stages__left-text {
    font-size: 33px;
    padding-right: 35px;
    padding-left: 69px;
  }
}
@media (max-width: 576px) {
  .section-stages__left-text {
    font-size: 24px;
    padding-right: 40px;
    padding-top: 40px;
    padding-left: 80px;
    line-height: 34px;
    padding-bottom: 40px;
  }
}
.section-stages__left-text:before {
  content: "";
  width: 40px;
  height: 40px;
  background: #FF6600;
  position: absolute;
  left: 60px;
  top: 54px;
}
@media (max-width: 1280px) {
  .section-stages__left-text:before {
    left: 36px;
  }
}
@media (max-width: 1200px) {
  .section-stages__left-text:before {
    left: 19px;
    top: 32px;
  }
}
@media (max-width: 576px) {
  .section-stages__left-text:before {
    left: 33px;
    top: 32px;
    width: 30px;
    height: 30px;
  }
}
.section-stages__left img {
  width: 101%;
  transition: all 0.3s linear;
  transform: scale(1);
}
@media (max-width: 1200px) {
  .section-stages__left img {
    width: 111%;
  }
}
@media (max-width: 800px) {
  .section-stages__left img {
    height: 100%;
    width: auto;
  }
}
.section-stages__left img:hover {
  transition: all 0.3s linear;
  transform: scale(1.1);
}
.section-stages__middle {
  padding-top: 53px;
  width: 509px;
}
@media (max-width: 1280px) {
  .section-stages__middle {
    width: 32%;
    padding-top: 20px;
  }
}
@media (max-width: 1200px) {
  .section-stages__middle {
    width: 50%;
  }
}
@media (max-width: 576px) {
  .section-stages__middle {
    width: 100%;
    padding-top: 40px;
  }
}
.section-stages__middle li {
  display: flex;
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #777777;
  list-style: none;
  padding-left: 76px;
  padding-right: 76px;
  margin-bottom: 71px;
  align-items: center;
}
@media (max-width: 1200px) {
  .section-stages__middle li {
    padding-left: 24px;
  }
}
@media (max-width: 576px) {
  .section-stages__middle li {
    padding-left: 0;
    margin-bottom: 57px;
    padding-right: 0;
  }
}
.section-stages__middle li:last-child {
  margin-bottom: 0;
}
.section-stages__num {
  font-weight: 100;
  font-size: 64px;
  line-height: 36px;
  color: black;
  position: relative;
  margin-right: 30px;
}
.section-stages__num:before {
  content: "";
  background: #dadada;
  opacity: 0.5;
  border-radius: 50%;
  width: 53px;
  display: block;
  height: 53px;
  position: absolute;
  top: -10px;
  left: 13px;
  z-index: -1;
}
.section-stages__right {
  overflow: hidden;
}
@media (max-width: 1280px) {
  .section-stages__right {
    width: 28%;
  }
}
@media (max-width: 1200px) {
  .section-stages__right {
    display: flex;
    width: 100%;
    height: 378px;
    margin-top: 20px;
    height: auto;
  }
}
@media (max-width: 576px) {
  .section-stages__right {
    margin-top: 40px;
    flex-wrap: wrap;
  }
}
.section-stages__right img {
  transition: all 0.3s linear;
  transform: scale(1);
}
@media (max-width: 1200px) {
  .section-stages__right img {
    width: 50vw;
    height: 241px;
    object-fit: cover;
  }
}
@media (max-width: 576px) {
  .section-stages__right img {
    width: 92vw;
    height: 88vw;
  }
}
.section-stages__right img:hover {
  transition: all 0.3s linear;
  transform: scale(1.1);
}
.section-stages__right-bottom {
  z-index: 1;
  position: relative;
  margin-top: -5px;
  padding: 60px 30px;
  padding-bottom: 0;
}
@media (max-width: 1200px) {
  .section-stages__right-bottom {
    margin-top: 0px;
  }
}
@media (max-width: 576px) {
  .section-stages__right-bottom {
    padding: 60px 30px;
    padding-left: 20px;
  }
}
.section-stages__right-bottom img {
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
}
.section-stages__right-bottom li {
  color: #fff;
  list-style: none;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.section-stages__right-bottom li:last-child {
  margin-top: 48px;
}
.section-stages__right-bottom .section-stages__num {
  color: #fff;
}
.section-advantages {
  margin-top: 100px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 800px) {
  .section-advantages {
    margin-top: 80px;
  }
}
.section-advantages .container {
  height: 542px;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
}
@media (max-width: 800px) {
  .section-advantages .container {
    height: auto;
  }
}
.section-advantages__slider {
  font-size: 24px;
  line-height: 36px;
  color: #dadada;
  margin-left: 120px;
  width: 294px !important;
  position: relative;
}
@media (max-width: 1200px) {
  .section-advantages__slider {
    margin-left: 8%;
    width: 44% !important;
  }
}
@media (max-width: 800px) {
  .section-advantages__slider {
    width: 100% !important;
    font-size: 22px;
    margin-top: 40px;
    margin-left: 0;
  }
}
@media (max-width: 800px) {
  .section-advantages__slider .slide {
    margin-right: 20px;
  }
}
.section-advantages__slider strong {
  font-weight: 600;
  display: block;
}
.section-advantages__slider .owl-dots {
  padding: 10px 15px;
  display: inline-flex;
  position: absolute;
  padding-bottom: 4px;
  margin-top: 30px;
  position: relative;
  left: -16px;
}
@media (max-width: 800px) {
  .section-advantages__slider .owl-dots {
    margin-top: 4px;
    margin-bottom: 30px;
  }
}
.section-advantages__slider .owl-dot {
  background: rgba(255, 255, 255, 0.5) !important;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  margin: 0 4px;
  display: block;
}
.section-advantages__slider .owl-dot.active {
  background: none !important;
  border: 3px solid #ff6600;
  width: 14px;
  height: 14px;
  position: relative;
  top: -2px;
}
.section-advantages__bgs img {
  display: none;
  position: absolute;
  z-index: -1;
  width: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}
@media (max-width: 1370px) {
  .section-advantages__bgs img {
    height: 100%;
  }
}
.section-advantages__bgs img:first-child {
  display: block;
}
.section-advantages__wrap {
  width: 990px;
  height: 335px;
  border: 1px solid #FF6600;
  padding: 45px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1200px) {
  .section-advantages__wrap {
    width: 96%;
  }
}
@media (max-width: 800px) {
  .section-advantages__wrap {
    margin: 32px 0;
    width: 92%;
    flex-wrap: wrap;
    height: auto;
    padding: 14px;
  }
}
.section-advantages__title {
  font-weight: 600;
  font-size: 40px;
  line-height: 54px;
  padding-left: 75px;
  color: #ffffff;
  position: relative;
  width: 213px;
  width: 380px;
}
@media (max-width: 1200px) {
  .section-advantages__title {
    font-size: 32px;
  }
}
@media (max-width: 800px) {
  .section-advantages__title {
    font-size: 24px;
    line-height: 34px;
    width: 100%;
    padding-left: 66px;
  }
}
.section-advantages__title:before {
  content: "";
  width: 40px;
  height: 40px;
  background: #ff6600;
  position: absolute;
  left: 0px;
  top: 2px;
}
@media (max-width: 800px) {
  .section-advantages__title:before {
    width: 30px;
    height: 30px;
    left: 15px;
    top: -1px;
  }
}

.section-clients {
  margin-top: 100px;
}
.section-clients .container {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .section-clients .container {
    flex-wrap: wrap;
    text-align: center;
  }
}
.section-clients__text {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  width: 302px;
}
@media (max-width: 1200px) {
  .section-clients__text {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
  }
}
.section-clients__slider {
  width: 1000px !important;
}
@media (max-width: 1280px) {
  .section-clients__slider {
    width: 800px !important;
  }
}
@media (max-width: 1200px) {
  .section-clients__slider {
    width: 100% !important;
  }
}
.section-clients__slider a {
  display: block;
  width: 275px;
  height: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #dadada;
  width: 100%;
  margin: 0 !important;
}
.section-clients__slider a img {
  max-height: 70px;
  max-height: 80px;
  width: auto !important;
}
.section-clients__slider .owl-nav {
  position: absolute;
  left: -400px;
  top: 80px;
}
@media (max-width: 1370px) {
  .section-clients__slider .owl-nav {
    top: 114px;
    left: -20%;
  }
}
@media (max-width: 1280px) {
  .section-clients__slider .owl-nav {
    left: -400px;
  }
}
.section-clients__slider .owl-prev,
.section-clients__slider .owl-next {
  height: 40px;
}
.section-clients__slider .owl-prev {
  margin-right: 20px;
}
.section-clients__slider .owl-dots {
  padding: 10px 15px;
  display: inline-flex;
  padding-bottom: 4px;
  margin: 0 auto;
  margin-top: 40px;
}
.section-clients__slider .owl-dot {
  background: rgba(0, 0, 0, 0.3) !important;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  margin: 0 4px;
  display: block;
}
.section-clients__slider .owl-dot.active {
  background: none !important;
  border: 3px solid #ff6600;
  width: 14px;
  height: 14px;
  position: relative;
  top: -2px;
}

.section-projects {
  margin-top: 100px;
  width: 100vw;
  overflow-x: hidden;
}
@media (max-width: 576px) {
  .section-projects {
    overflow: hidden;
  }
}
.section-projects .container {
  display: flex;
}
@media (max-width: 1200px) {
  .section-projects .container {
    flex-wrap: wrap;
  }
}
.section-projects__text {
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  /* or 135% */
  color: #000;
  width: 300px;
  position: relative;
  padding-left: 30px;
  padding-top: 30px;
}
@media (max-width: 1200px) {
  .section-projects__text {
    font-size: 32px;
    padding: 0;
    padding-top: 30px;
    width: 100%;
  }
}
.section-projects__text:before {
  content: "";
  width: 24px;
  height: 24px;
  background: #ff6600;
  position: absolute;
  left: 0px;
  top: 2px;
}
@media (max-width: 1200px) {
  .section-projects__text:before {
    left: 13px;
  }
}
@media (max-width: 576px) {
  .section-projects__text:before {
    left: 13px;
    width: 30px;
    height: 30px;
    top: 22px;
  }
}
@media (max-width: 1200px) {
  .section-projects__text span {
    padding-left: 40px;
  }
}
@media (max-width: 800px) {
  .section-projects__text span {
    display: block;
  }
}
@media (max-width: 576px) {
  .section-projects__text span {
    display: block;
    font-size: 24px;
    line-height: 34px;
    padding-left: 63px;
  }
}
.section-projects__descrs {
  margin-top: 50px;
  margin-left: 130px;
}
@media (max-width: 1200px) {
  .section-projects__descrs {
    margin-left: 0px;
    margin-top: 16px;
  }
}
@media (max-width: 576px) {
  .section-projects__descrs {
    margin-top: 26px;
    width: 109%;
    left: 14px;
    position: relative;
  }
}
.section-projects__descr {
  width: 430px;
  background: #F4F1EF;
  padding: 48px 55px;
  font-size: 18px;
  display: none;
}
@media (max-width: 1200px) {
  .section-projects__descr {
    margin: 0 auto;
    width: 96%;
    padding: 30px 30px;
  }
}
@media (max-width: 576px) {
  .section-projects__descr {
    font-size: 18px;
    padding-left: 44px;
  }
}
.section-projects__descr:first-child {
  display: block;
}
.section-projects__descr-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  width: 79%;
}
@media (max-width: 576px) {
  .section-projects__descr-title {
    width: 100%;
    font-size: 20px;
  }
}
.section-projects__descr-address {
  font-weight: 300;
  margin-top: 40px;
  margin-bottom: 7px;
}
@media (max-width: 1200px) {
  .section-projects__descr-address {
    margin-top: 15px;
  }
}
.section-projects__descr-address:before {
  content: "";
  width: 23px;
  height: 24px;
  background: url(../images/Location.svg);
  background-size: contain;
  display: inline-block;
  position: relative;
  top: 6px;
  background-repeat: no-repeat;
  margin-right: 5px;
}
.section-projects__descr p {
  line-height: 30px;
  margin-bottom: 16px;
  width: 73%;
  color: #777777;
  font-weight: 400;
}
@media (max-width: 576px) {
  .section-projects__descr p {
    width: 100%;
    font-size: 18px;
  }
}
.section-projects__descr p span, .section-projects__descr p strong {
  color: #000;
  padding-left: 0px;
  font-weight: 600;
}
@media (max-width: 576px) {
  .section-projects__descr p span, .section-projects__descr p strong {
    font-size: 18px;
    display: inline-block;
  }
}
.section-projects__slider {
  width: 200%;
  left: 13%;
  position: relative;
}
@media (max-width: 1200px) {
  .section-projects__slider {
    left: 0;
  }
}
@media (max-width: 576px) {
  .section-projects__slider {
    width: 110% !important;
    left: -5%;
  }
}
.section-projects__slider .projects__see-all {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-transform: uppercase;
  color: #FFFFFF;
  width: 250px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  transition: all 0.3s linear;
}
.section-projects__slider .projects__see-all:hover {
  background: #FF6600;
}
.section-projects__slider .owl-item {
  opacity: 0.3;
  transition: all 0.3s linear;
}
.section-projects__slider .owl-item.active {
  opacity: 1;
}
.section-projects__slider .owl-item.active + .owl-item.active {
  opacity: 0.3;
}
.section-projects__slider .owl-nav {
  margin-top: 48px;
  position: relative;
  left: 740px;
}
@media (max-width: 1370px) {
  .section-projects__slider .owl-nav {
    left: 485px;
  }
}
@media (max-width: 1200px) {
  .section-projects__slider .owl-nav {
    left: 75%;
    position: absolute;
    top: -155px;
  }
}
@media (max-width: 740px) {
  .section-projects__slider .owl-nav {
    left: 65%;
  }
}
@media (max-width: 576px) {
  .section-projects__slider .owl-nav {
    display: flex;
    left: 0%;
    position: absolute;
    top: -220px;
    width: 100%;
    justify-content: space-between;
    width: 87%;
    left: 6%;
  }
}
.section-projects__slider .owl-prev {
  margin-right: 100px;
}
.section-projects__slider .slide {
  width: 931px;
  height: 444px;
  display: block;
}
@media (max-width: 576px) {
  .section-projects__slider .slide {
    width: 100vw;
    height: 65vw;
  }
}
.section-projects__slider .slide .section-projects__proj-slider {
  height: 100%;
}
.section-projects__slider .slide .section-projects__proj-slider .owl-item {
  opacity: 1;
  transition: all 0.3s linear;
}
.section-projects__slider .slide .section-projects__proj-slider .owl-item.active + .owl-item.active {
  opacity: 1;
}
.section-projects__slider .slide .section-projects__proj-slider img {
  width: 931px;
  height: 444px;
  object-fit: cover;
}
@media (max-width: 576px) {
  .section-projects__slider .slide .section-projects__proj-slider img {
    width: 106%;
    height: 65vw;
  }
}
.section-projects__slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.section-projects__slider .owl-dots {
  background: rgba(0, 0, 0, 0.4);
  padding: 10px 15px;
  display: inline-flex;
  position: absolute;
  top: 415px;
  left: 2px;
  padding-bottom: 4px;
}
@media (max-width: 576px) {
  .section-projects__slider .owl-dots {
    top: unset;
    margin: 0 auto;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.section-projects__slider .owl-prev {
  transform: rotate(180deg);
}
.section-projects__slider .owl-dot {
  background: rgba(255, 255, 255, 0.5) !important;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  margin: 0 4px;
  display: block;
}
.section-projects__slider .owl-dot.active {
  background: none !important;
  border: 3px solid #ff6600;
  width: 14px;
  height: 14px;
  position: relative;
  top: -2px;
}

.section-equipment {
  margin-top: 100px;
  background: #F4F1EF;
  padding-top: 44px;
  position: relative;
  padding-bottom: 190px;
  margin-bottom: 494px;
}
@media (max-width: 800px) {
  .section-equipment {
    text-align: center;
  }
}
@media (max-width: 1370px) {
  .section-equipment {
    margin-bottom: 380px;
  }
}
@media (max-width: 576px) {
  .section-equipment {
    margin-top: 80px;
    padding-top: 14px;
    text-align: center;
    margin-bottom: 130vw;
  }
}
.section-equipment__title {
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  /* or 135% */
  text-align: left;
  color: #000;
  position: relative;
  padding-left: 30px;
  padding-top: 30px;
}
@media (max-width: 1200px) {
  .section-equipment__title {
    font-size: 32px;
    width: 60%;
    line-height: 40px;
    padding-left: 40px;
  }
}
@media (max-width: 576px) {
  .section-equipment__title {
    display: block;
    font-size: 24px;
    line-height: 34px;
    padding-left: 63px;
    width: 100%;
  }
}
.section-equipment__title:before {
  content: "";
  width: 24px;
  height: 24px;
  background: #ff6600;
  position: absolute;
  left: 0px;
  top: 2px;
}
@media (max-width: 1200px) {
  .section-equipment__title:before {
    left: 12px;
  }
}
@media (max-width: 576px) {
  .section-equipment__title:before {
    left: 12px;
    width: 30px;
    height: 30px;
    top: 20px;
  }
}
.section-equipment__slider {
  margin-top: 40px;
  position: absolute !important;
  top: 141px;
  width: 100%;
  /* margin: 0 auto; */
  width: 96%;
  /* margin: 0 auto; */
  left: 2%;
}
@media (max-width: 1200px) {
  .section-equipment__slider {
    left: 0%;
  }
}
@media (max-width: 800px) {
  .section-equipment__slider {
    width: 98% !important;
    left: 1%;
  }
}
.section-equipment__slider .slide {
  width: 450px;
  height: 600px;
  position: relative;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  display: block;
  /* or 150% */
  background-size: 100% !important;
  background-repeat: no-repeat;
  padding: 28px;
  transition: all ease-in-out 0.3s !important;
  text-align: left;
  /* white */
  color: #ffffff;
}
@media (max-width: 1370px) {
  .section-equipment__slider .slide {
    width: 380px;
    height: 493px;
  }
}
@media (max-width: 800px) {
  .section-equipment__slider .slide {
    width: 48vw;
    height: 63vw;
  }
}
@media (max-width: 576px) {
  .section-equipment__slider .slide {
    width: 99vw;
    height: 132vw;
  }
}
.section-equipment__slider .slide:hover {
  background-size: 110% !important;
  transition: all ease-in-out 0.3s !important;
}
.section-equipment__slider .slide img {
  position: absolute;
  top: 0;
  z-index: 0;
}
.section-equipment__slider .slide__subtitle {
  font-size: 16px;
  z-index: 2;
}
.section-equipment__slider .slide__title {
  z-index: 2;
}
.section-equipment__slider .owl-dots {
  background: none !important;
  padding: 10px 15px;
  display: inline-flex;
  padding-bottom: 4px;
  margin: 0 auto;
  margin-top: 40px;
}
.section-equipment__slider .owl-dot {
  background: rgba(0, 0, 0, 0.3) !important;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  margin: 0 4px;
  display: block;
}
.section-equipment__slider .owl-dot.active {
  background: none !important;
  border: 3px solid #ff6600;
  width: 14px;
  height: 14px;
  position: relative;
  top: -2px;
}
.section-equipment__slider .owl-nav {
  position: absolute;
  top: -110px;
  right: 10%;
}
@media (max-width: 740px) {
  .section-equipment__slider .owl-nav {
    right: 2%;
  }
}
@media (max-width: 576px) {
  .section-equipment__slider .owl-nav {
    display: none;
  }
}
.section-equipment__slider .owl-prev {
  transform: rotate(180deg);
  margin-right: 100px;
}

.section-certificates {
  background: #F4F1EF;
  padding-bottom: 70px;
  padding-top: 70px;
}
.section-certificates__text {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  width: 273px;
  margin-right: 94px;
}
@media (max-width: 1280px) {
  .section-certificates__text {
    margin-right: 40px;
  }
}
@media (max-width: 1200px) {
  .section-certificates__text {
    width: 100%;
    text-align: center;
    margin: 0;
    margin-bottom: 40px;
  }
}
.section-certificates .container {
  display: flex;
}
@media (max-width: 1200px) {
  .section-certificates .container {
    flex-wrap: wrap;
    text-align: center;
  }
}
.section-certificates__slider {
  width: 73% !important;
}
@media (max-width: 1200px) {
  .section-certificates__slider {
    width: 100% !important;
  }
}
.section-certificates__slider .slide {
  width: 350px;
}
@media (max-width: 1280px) {
  .section-certificates__slider .slide {
    width: 300px;
  }
}
@media (max-width: 1200px) {
  .section-certificates__slider .slide {
    text-align: center;
  }
}
@media (max-width: 1050px) {
  .section-certificates__slider .slide {
    width: 50vw;
  }
}
@media (max-width: 576px) {
  .section-certificates__slider .slide {
    width: 92vw;
  }
}
.section-certificates__slider .slide p {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  width: 80%;
}
@media (max-width: 1280px) {
  .section-certificates__slider .slide p {
    width: 87%;
  }
}
@media (max-width: 1200px) {
  .section-certificates__slider .slide p {
    width: 100%;
  }
}
.section-certificates__slider .slide img {
  width: 140px !important;
  height: auto;
  margin-bottom: 24px;
}
@media (max-width: 1200px) {
  .section-certificates__slider .slide img {
    margin: 0 auto;
    margin-bottom: 24px;
    width: 73% !important;
  }
}
@media (max-width: 1050px) {
  .section-certificates__slider .slide img {
    width: 50% !important;
  }
}
@media (max-width: 1050px) {
  .section-certificates__slider .slide p {
    padding: 0 19%;
  }
}
.section-certificates__slider .owl-dots {
  background: none !important;
  padding: 10px 15px;
  display: inline-flex;
  padding-bottom: 4px;
  margin: 0 auto;
  margin-top: 40px;
}
.section-certificates__slider .owl-dot {
  background: rgba(0, 0, 0, 0.3) !important;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  margin: 0 4px;
  display: block;
}
.section-certificates__slider .owl-dot.active {
  background: none !important;
  border: 3px solid #ff6600;
  width: 14px;
  height: 14px;
  position: relative;
  top: -2px;
}
.section-certificates__slider .owl-nav {
  position: absolute;
  left: -35%;
  top: 46%;
}
@media (max-width: 1370px) {
  .section-certificates__slider .owl-nav {
    left: -37%;
    top: 54%;
  }
}
@media (max-width: 1280px) {
  .section-certificates__slider .owl-nav {
    left: -312px;
  }
}
.section-certificates__slider .owl-prev {
  margin-right: 20px;
}
.section-certificates__slider .owl-prev,
.section-certificates__slider .owl-nav {
  height: 40px;
}

.section-suppliers {
  padding-top: 70px;
  padding-bottom: 70px;
}
.section-suppliers__title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
}
.section-suppliers__wrap {
  display: flex;
  justify-content: space-around;
  width: 91%;
  margin: 0 auto;
  margin-top: 64px;
}
@media (max-width: 1670px) {
  .section-suppliers__wrap {
    justify-content: space-between;
  }
}
@media (max-width: 1200px) {
  .section-suppliers__wrap {
    flex-wrap: wrap;
  }
}
.section-suppliers__suplier {
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1670px) {
  .section-suppliers__suplier {
    width: auto;
  }
}
@media (max-width: 1280px) {
  .section-suppliers__suplier {
    width: 13%;
  }
}
@media (max-width: 1200px) {
  .section-suppliers__suplier {
    width: 26%;
    margin-bottom: 20px;
  }
}
@media (max-width: 800px) {
  .section-suppliers__suplier {
    width: 33%;
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {
  .section-suppliers__suplier {
    width: 50%;
    margin-bottom: 20px;
  }
}
.section-suppliers__suplier img {
  max-height: 122px;
}
@media (max-width: 1670px) {
  .section-suppliers__suplier img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}
@media (max-width: 1050px) {
  .section-suppliers__suplier img {
    max-height: unset;
    max-width: 84%;
  }
}
@media (max-width: 576px) {
  .section-suppliers__suplier img {
    max-width: 64%;
  }
}

.section-contacts {
  border-top: 1px solid #dadada;
}
.section-contacts__title {
  font-weight: 700;
  margin-bottom: 5px;
}
.section-contacts .container {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1370px) {
  .section-contacts .container {
    justify-content: space-around;
  }
}
@media (max-width: 1200px) {
  .section-contacts .container {
    flex-wrap: wrap;
  }
}
.section-contacts__left {
  padding-top: 35px;
  width: 458px;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  line-height: 30px;
}
@media (max-width: 1370px) {
  .section-contacts__left {
    width: 32%;
  }
}
@media (max-width: 1200px) {
  .section-contacts__left {
    width: 100%;
    padding-left: 5%;
  }
}
.section-contacts__left span {
  color: #777777;
}
.section-contacts__left p {
  margin-bottom: 5px;
}
.section-contacts__right {
  padding-top: 35px;
  width: 458px;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  line-height: 30px;
}
@media (max-width: 1370px) {
  .section-contacts__right {
    width: 32%;
  }
}
@media (max-width: 1200px) {
  .section-contacts__right {
    width: 100%;
    padding-left: 5%;
  }
}
.section-contacts__right span {
  color: #777777;
}
.section-contacts__right p {
  margin-bottom: 5px;
}
.section-contacts__middle {
  width: 380.02px;
  background: #F4F1EF;
  padding: 0 55px;
  height: 444px;
  height: 431px;
}
@media (max-width: 1370px) {
  .section-contacts__middle {
    width: 310.02px;
    padding: 0px 28px;
  }
}
@media (max-width: 1200px) {
  .section-contacts__middle {
    width: 100%;
    width: 90%;
    margin: 0 auto;
    margin-top: 45px;
  }
}
.section-contacts__middle-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin: 0 auto;
  margin-top: 32px;
  text-align: center;
  margin-bottom: 30px;
}
.section-contacts__middle input {
  height: 33px;
  border: none;
  color: #777777;
  background: none;
  width: 100%;
  border-bottom: 1px solid #dadada;
  margin-bottom: 30px;
  font-size: 14px;
}
.section-contacts__middle input:focus, .section-contacts__middle input:active {
  border: none;
  color: #000;
  border-bottom: 1px solid #000;
}
.section-contacts__middle textarea {
  border: none;
  font-size: 14px;
  color: #777777;
  background: none;
  width: 100%;
  border-bottom: 1px solid #dadada;
  margin-bottom: 30px;
  height: 55px;
  font-family: "Montserrat";
}
.section-contacts__middle textarea:focus, .section-contacts__middle textarea:active {
  border: none;
  color: #000;
  border-bottom: 1px solid #000;
}
.section-contacts__middle .accept {
  font-size: 12px;
  line-height: 18px;
  /* or 150% */
  /* gray/1 */
  color: #777777;
}
.section-contacts__middle .btn {
  margin-top: 14px;
  width: 100%;
}
.section-contacts__middle .btn span {
  border-color: #F4F1EF transparent transparent transparent;
}
.section-contacts__map {
  width: 450px;
  height: 225.15px;
  margin-top: 36px;
}
@media (max-width: 1370px) {
  .section-contacts__map {
    width: 96%;
  }
}
@media (max-width: 1200px) {
  .section-contacts__map {
    height: 48vw;
    width: 94%;
  }
}
@media (max-width: 576px) {
  .section-contacts__map {
    height: 94vw;
  }
}

.btn {
  width: 270px;
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
  /* primary / 2 */
  background: #FF6600;
  transition: all 0.3s linear;
}
.btn:hover {
  transition: all 0.3s linear;
  background: #ff8533;
}
.btn span {
  position: absolute;
  top: 0;
  left: 0;
  border-style: solid;
  border-width: 20px 20px 0 0;
  border-color: #ffffff transparent transparent transparent;
}

.footer {
  margin-top: 30px;
  background: #000;
  height: 50px;
}
@media (max-width: 576px) {
  .footer {
    height: auto;
    padding: 40px 0px;
  }
}
.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
@media (max-width: 1200px) {
  .footer .container {
    padding: 0 2%;
  }
}
@media (max-width: 576px) {
  .footer .container {
    flex-wrap: wrap;
  }
}
.footer__socials {
  display: flex;
  align-items: center;
}
@media (max-width: 576px) {
  .footer__socials {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
}
.footer__socials svg {
  width: 28px;
  height: auto;
  margin-right: 12px;
}
.footer__copyright {
  color: #777777;
  font-size: 12px;
}
@media (max-width: 576px) {
  .footer__copyright {
    width: 60%;
    margin: 0 20%;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    line-height: 22px;
  }
}
.footer__dev {
  display: flex;
  align-items: center;
  color: #777777;
  font-size: 12px;
}
@media (max-width: 576px) {
  .footer__dev {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.footer__dev svg {
  width: 95px;
  height: auto;
  margin-left: 14px;
}

.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}
.checkbox input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 1px solid #dadada;
}
.checkbox .checkmark.validate-err {
  border: 1px solid red;
}
.checkbox input[type=checkbox] input ~ .checkmark {
  background-color: #ccc;
}
.checkbox input[type=checkbox]:checked ~ .checkmark {
  background-color: #FF6600;
}
.checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox input[type=checkbox]:checked ~ .checkmark:after {
  display: block;
}
.checkbox .checkmark:after {
  left: 7px;
  top: -1px;
  width: 7px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

input.validate-err, textarea.validate-err {
  border-bottom: 1px solid red;
}

/**
 * Modals ($modals)
 */
/* 1. Ensure this sits above everything when visible */
.modal {
  position: fixed;
  z-index: 100000000; /* 1 */
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-heading {
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
  text-align: center;
  margin-bottom: 36px;
}

.modal.is-visible {
  visibility: visible;
}

.modal-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.modal.is-visible .modal-overlay {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.modal-wrapper {
  position: absolute;
  z-index: 9999;
  padding: 46px;
  width: 393px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 0px 41px rgba(0, 0, 0, 0.25);
}

.modal-transition {
  transition: all 0.3s 0.12s;
  transform: translateY(-10%);
  opacity: 0;
}

.modal.is-visible .modal-transition {
  transform: translateY(0);
  opacity: 1;
}

input[type=text] {
  margin-bottom: 16px;
}

input[type=number] {
  margin-bottom: 16px;
}

.close {
  background: url(../images/form-close-X.svg) no-repeat;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 20px;
  right: 20px;
}
.close:hover {
  cursor: pointer;
}

#thanks p {
  text-align: center;
}

.arr1 {
  transition: all 0.3s linear;
}
.arr1:hover {
  transition: all 0.3s linear;
  fill: #FF6600;
}

.arr2 {
  transition: all 0.3s linear;
}
.arr2:hover {
  transition: all 0.3s linear;
  fill: #FF6600;
}

.up-btn {
  position: fixed;
  right: 1%;
  bottom: 10%;
  z-index: 999;
  transform: scale(0.9);
  transition: all 0.3s linear;
  cursor: pointer;
}
.up-btn:hover {
  transform: scale(1);
  transition: all 0.3s linear;
}